import React from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import Tooltip from '@mui/material/Tooltip';

import '../App.css';
import YoutubeEmbed from '../components/YoutubeEmbed';
import { Item2 } from '../styles';
import Header from '../components/HeaderComponent';
import Config from '../config.json';

function isMobile() {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

function MainPage() {
  if (isMobile()) {
  } else {
  }
  
  return (
    <Grid container md={12} spacing={0} className='main-frame'>
      <Grid container md={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <Grid xs={12}>
        <Header title='Premium Edition' showLogin={true} />
      </Grid>
      <Grid container spacing={0} style={{ width:'100%', marginTop: '4em' }}>
          <Grid md={4} xs={12} sm={12} style={{ marginTop: '2em' }}>
          </Grid>
          <Grid md={4} xs={12} sm={12} style={{ marginTop: '2em', textAlign: 'center' }}>
          <div className="yapp">
            <YoutubeEmbed />
          </div>
        </Grid>
      </Grid>


      <Grid container spacing={0} md={12}>
        <Grid container xs={12} sm={12} lg={12} style={{ marginTop: '1em' }}>
          <Grid md={4} xs={12} sm={12} style={{ marginTop: '4em' }}>
            <Item2 style={{ height: '4em' }}>
              <Link to='/compound-search' style={{ textDecoration: 'none' }}>
                <div><HexagonOutlinedIcon color="primary" style={{ fontSize: '5em' }} /></div>
                <Tooltip title="Structure search for compounds">
                  <Button variant="contained" style={{ textDecoration: 'none' }}>
                    Search by compound
                  </Button>
                </Tooltip>
              </Link>
            </Item2>
          </Grid>
          <Grid md={4} xs={12} sm={12} style={{ marginTop: '4em' }}>
            <Item2 style={{ height: '4em' }}>
              <Link to='/spectrum-search' style={{ textDecoration: 'none' }}>
                <div><AlignVerticalBottomIcon style={{ fontSize: '5em' }} color="primary" /></div>
                <Tooltip title="Find components of your mixture by NMR spectrum">
                  <Button variant="contained">
                    Search by spectrum
                  </Button>
                </Tooltip>
              </Link>
            </Item2>
          </Grid>
          <Grid md={4} xs={12} sm={12} style={{ marginTop: '4em' }}>
            <Item2 style={{ height: '2.6em' }}>
              <Link to='/publication-search' style={{ textDecoration: 'none' }}>
                <div><AutoStoriesIcon style={{ fontSize: '5em' }} color="primary" /></div>
                <Tooltip title="See compounds and spectra described in the paper">
                  <Button variant="contained">Search by publication</Button>
                </Tooltip>
              </Link>
            </Item2>
          </Grid>
        </Grid>
 

        <Grid container style={{ margin: '4em', marginTop: '4em' }} xs={12}>
          <Grid md={1}></Grid>
          <Grid md={5}>

            <Grid style={{ marginTop: '4em', fontWeight: 600 }} xs={12}>
              The most popular questions
            </Grid>
            <Grid style={{ marginTop: '1em' }} xs={12}>
              <a href='/help#wicnftc'>
                <Button variant="outlined" style={{ width: 'fit-content', textTransform: 'none' }}>Why can't I find a chemical compound?</Button>
              </a>
            </Grid>
            <Grid style={{ marginTop: '1em' }} xs={12}>
              <a href='/help#wcod'>
                <Button variant="outlined" style={{ width: 'fit-content', textTransform: 'none' }}>Which search options are available?</Button>
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ margin: '4em', marginTop: '2em' }} md={12}>
          <Grid md={1}></Grid>
          <Grid md={7}>
            <Item2 style={{ textAlign: 'left', fontSize: 'medium', lineHeight: '200%' }}>
              <div>Contacts: {Config.contact}</div>
              <div style={{ fontWeight: '600', paddingBottom: '3em' }}>If you have questions and ideas on improvement please write us.</div>
            </Item2>
          </Grid>
          <Grid md={3}>
            <Item2 style={{ textAlign: 'center', padding: '1em', fontSize: 'medium', lineHeight: '200%' }}>
              <Link to='/help' style={{ textDecoration: 'none' }}>
                <Tooltip title="Tips on using OdanChem: FAQ">
                  <Button variant="outlined" style={{ width: 'fit-content', textTransform: 'none' }}>Tips on using OdanChem</Button>
                </Tooltip>
              </Link>
            </Item2>
          </Grid>
          <Grid xs={1}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MainPage;

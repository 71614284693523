import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import '../../App.css';
import { Item, Item2 } from '../../styles';
import Button from '@mui/material/Button';
import { getSpectra } from '../../services/Spectrum';
import Header from '../../components/HeaderComponent';
import MoleculeView from '../../components/MoleculeView';
import { copyPublication } from '../../services/Publication';
import ProgressControl from '../../components/ProgressControl';
import ISpectraModel from '../../schemas/Spectrum/ISpectraModel';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';
import { fetchGet, reportError } from '../../services/GettingData';
import PublicationComponent from '../../components/Publication/PublicationComponent';
import IExactSearchResponse from '../../schemas/Compound/IExactSearchResponse';
import IReportSpectraErrorModel from '../../schemas/IReportSpectraErrorModel';
import Config from '../../config.json';
import ReportConfirmationDialog from '../../components/Dialog/ReportConfirmationDialog';

type SpectrumProps = { spectrId: string };
type SpectrumState = {
  isLoading: boolean,
  isDialogOpened: boolean,
  isMoleculeInContainer: boolean,
  spectraModel: ISpectraModel,
  svg?: string,
  publicationText: string,
  publicationUrn: string,
  isCopied: boolean,
};


class SpectrumPage extends React.PureComponent<SpectrumProps, SpectrumState> {
  constructor(props: { spectrId: string }) {
    super(props);
    const spectrId = (props as any).match.params['spectrid'];

    this.state = {
      isLoading: true,
      isDialogOpened: false,
      spectraModel: {
        id: spectrId,
        compound_id: '0',
        doi: '',
        molecule_svg: '',
      },
      svg: '',
      isMoleculeInContainer: false,
      publicationText: '',
      publicationUrn: '',
      isCopied: false,
    };
  }


  async componentDidMount(): Promise<void> {
    try {
      const spectra = await getSpectra(this.state.spectraModel.id) as ISpectraModel;
      const compound = await this.getCompound(spectra.compound_id);
      this.setState({ spectraModel: spectra, svg: compound.svg, isLoading: false });
    }
    catch (e: any) {
      if (e instanceof NotAuthorizedError) {
        (this.props as any).history.push({ pathname: '/login', state: { backTo: '/spectrum/' + this.state.spectraModel.id, 
                                           welcomeMessage: true } })
      } 
    }
  }


  async getCompound(moleculeId: string): Promise<IExactSearchResponse> {
    this.setState({ isLoading: true });
    const response = await fetchGet('/compound/id/' + moleculeId, true, Config.business);
    return await response.json();
  }


  async onReportClick() {
    this.setState({ isDialogOpened: true });
  }


  onChangePublicationView = (publicationText : string, urn: string) => {
    this.setState({ publicationText: publicationText,
                    publicationUrn: urn,
                    isCopied: false })
  }


  blobToBase64 = (blob) => {

    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  render() {
     const pngString = encodeURIComponent(this.state.spectraModel.graph ? this.state.spectraModel.graph : '');
     const dataUri = `data:image/png;base64,${pngString}`;
    
    const onCloseDialog = async (confirmed: boolean, mistakeComment: string) => {

      this.setState({ isDialogOpened: false });
      if (confirmed) {
        const spectrError: IReportSpectraErrorModel = {
          spectrum_id: this.state.spectraModel.id,
          spectrum_string: this.state.spectraModel.spectrum_string ? this.state.spectraModel.spectrum_string : '',
          comment: mistakeComment
        }
        const errors = [spectrError];
        await reportError(JSON.stringify(errors));
      }
    }


    return (
      <Grid container spacing={0} className='main-frame'>
        <Grid xs={12}>
          <Item2><div style={{ height: '2em' }}></div></Item2>
        </Grid>
        <Grid xs={12}>
          <Header title='Spectrum' showLogin={true} />
        </Grid>

        <ReportConfirmationDialog isDialogOpened={this.state.isDialogOpened} onClose={onCloseDialog} />

        {this.state.isLoading ? <ProgressControl isLoading={this.state.isLoading} /> :
          <>
            <Grid sm={12} md={12} xs={12} container>
              <Grid md={4} sm={12} xs={12} style={{ padding: '1em', minWidth: '360px' }}>
                <Item style={{ width: '250px', marginLeft: '110px' }}>
                  <MoleculeView svgContent={this.state.svg} link={''} isMoleculeInContainer={true} />
                </Item>
              </Grid>

              <Grid md={8} sm={12} xs={12} style={{ padding: '1em' }}>
                <Item style={{ padding: '.3em' }}>
                  <div>{this.state.spectraModel.spectrum_string}</div>
                </Item>
                <PublicationComponent publicationId={this.state.spectraModel.publication_id} 
                                      onChange={this.onChangePublicationView}/>

                <Grid sm={12} container>
                  <Grid sm={12}>
                    <Button
                      className='report-error-spectrum'
                      onClick={() => this.onReportClick()}
                      variant='outlined'
                      sx={{ marginTop: '1em', float: 'right', color: '#505050', borderColor: '#505050' }}
                    >Report Error</Button>
                  </Grid>
                </Grid>
                <Grid>
                  <Button
                    className='operation-button'
                    onClick={async (event) => {
                      await copyPublication(event,
                        this.state.svg,
                        this.state.spectraModel.spectrum_string,
                        this.state.publicationText,
                        this.state.publicationUrn
                         )
                    }}
                    variant='outlined'
                    sx={{ marginTop: '1em', float: 'right', color: '#505050', borderColor: '#505050' }}>
                      { this.state.isCopied ? 'Copied' : 'Copy' }
                    </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid md={12} sm={12}>
              <div>
                {this.state.spectraModel.graph ?
                  <Grid xs={12}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Item style={{ width: '100%', marginTop: '0' }}>
                      <div className='graph-container'>
                        <img className='graph-qc' alt='' src={dataUri} style={{ maxWidth: '100%' }} />
                      </div>
                    </Item>
                  </Grid> : ''}
              </div>
            </Grid> </>}
      </Grid>
    );
  }
}
export default SpectrumPage;

import React from 'react';
import { useHistory } from "react-router-dom";
import { Switch, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import MainPage from './pages/MainPage';
import HelpPage from './pages/HelpPage';
import LoginPage from './pages/Login/LoginPage';
import SubSearchPage from './pages/Compound/SubSearchPage';
import CompoundSearch from './pages/Compound/CompoundSearch';
import RestorePage from './pages/Login/RestorePage';
import RegisterPage from './pages/Login/RegisterPage';
import ExactSearchPage from './pages/Compound/ExactSearchPage';
import PrivacyPolicyPage from './pages/Login/PrivacyPolicyPage';
import SpectrumPage from './pages/Spectrum/SpectrumPage';
import MoleculeEditorPage from './pages/Compound/MoleculeEditorPage';
import ConfirmationPage from './pages/Login/ConfirmationPage';
import PersonalInfoPage from './pages/Login/PersonalInfoPage';
import HistoryPage from './pages/History/HistoryPage';
import PublicationSearchPage from './pages/PublicationSearchPage';
import SpectrumSearchPage from './pages/Spectrum/SpectrumSearchPage';
import CompoundHistoryPage from './pages/History/CompoundHistoryPage';
import SpectrumComparePage from './pages/Spectrum/SpectrumComparePage';
import RestoreConfirmationPage from './pages/Login/RestoreConfirmationPage';
import PublicationHistoryPage from './pages/History/PublicationHistoryPage';
import SpectrumSearchResultPage from './pages/Spectrum/SpectrumSearchResultPage';

import theme from "./theme";
import { getSession } from './services/Login';

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/compound-search" render={requireAuth(CompoundSearch)} />

          <Route path="/editor/:session" >
            {(props: any) => <MoleculeEditorPage {...props} />}
          </Route>
      
          <Route path="/main" render={requireAuth(MainPage)} />
      
          <Route path="/help" >
            <HelpPage />
          </Route>

          <Route path="/sub-search/:page" >
            {(props: any) => <SubSearchPage {...props} />}
          </Route>

          <Route path="/exact-search/:molid" >
            {(props: any) => <ExactSearchPage {...props} />}
          </Route>

          {/* <Route path="/exact-search/" >
            {(props: any) => <ExactSearchPage {...props} />}
          </Route> */}
          <Route path="/exact-search/" render={requireAuth(ExactSearchPage)} />


          <Route path="/spectrum-search-result/:page" >
            {(props: any) => <SpectrumSearchResultPage {...props} />}
          </Route>
          {<Route path="/publication-search" >
            {(props: any) => <PublicationSearchPage {...props} />}
          </Route>}

          <Route path="/spectrum-search" render={requireAuth(SpectrumSearchPage)} />

          <Route path="/publication-search" render={requireAuth(PublicationSearchPage)} />

          <Route path="/main" render={requireAuth(MainPage)} />

          <Route path="/spectrum-search-result/:page" >
            {(props: any) => <SpectrumSearchResultPage {...props} />}
          </Route>

          <Route path="/spectrum/:spectrid" >
            {(props: any) => <SpectrumPage {...props} />}
          </Route>

          <Route path="/spectrum-compare/:spectrid" >
            {(props: any) => <SpectrumComparePage {...props} />}
          </Route>

          <Route exact path="/"
            render={() => { return (<Redirect to="/main" />) }}
          />

          <Route exact path="/register"
            render={(props: any) => { return (<RegisterPage {...props} />) }}
          />

          <Route exact path="/login"
            render={(props: any) => { return (<LoginPage {...props} />) }}
          />

          <Route exact path="/confirmation"
            render={(props: any) => { return (<ConfirmationPage {...props} />) }}
          />

         <Route exact path="/restore-confirmation"
            render={(props: any) => { return (<RestoreConfirmationPage {...props} />) }}
          />

          <Route exact path="/restore"
            render={(props: any) => { return (<RestorePage {...props} />) }}
          />

          <Route path="/personal" render={requireAuth(PersonalInfoPage)} />
          
          <Route exact path="/search-history"
            render={(props: any) => { return (<HistoryPage {...props} />) }}
          />

         <Route exact path="/publication-history"
            render={(props: any) => { return (<PublicationHistoryPage {...props} />) }}
          />

          <Route exact path="/compound-history"
            render={(props: any) => { return (<CompoundHistoryPage {...props} />) }}
          />

         <Route exact path="/privacy-policy"
            render={(props: any) => { return (<PrivacyPolicyPage {...props} />) }}
          />
        </Switch>
      </ThemeProvider>

    </Router>
  );
}

function requireAuth(Component) {
  return function AuthenticatedComponent(props) {
    if (!getSession()?.access_token) {
      if (!props.location.pathname || props.location.pathname === '/main')
        return <Redirect to="/login"/>;
      else  
        return <Redirect to={"/login?from=" + props.location.pathname.substring(1) }/>;
    }
    return <Component {...props} />;
  };
}

export default App;

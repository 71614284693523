import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = () => (
  <div className="video-responsive-a">
    <iframe
      max-width="400"
      //height="220"
      src={`https://www.youtube.com/embed/HOR1Unis-Mk`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="odanchem tutorial"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string
};

export default YoutubeEmbed;
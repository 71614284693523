import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';

import OurLogo from './OurLogo';
import { Item2 } from '../styles';
import ISession from '../schemas/Login/ISession';
import { getSession } from '../services/Login';
import Config from '../config.json';

type IHeaderProps = {
    title: string,
    helpAddress?: string,
    showLogin?: boolean,
};

const HeaderComponent: React.FC<IHeaderProps> = (props) => {

    const [session, setSession] = useState<ISession | undefined>(undefined);

    function renderLogin(session?: ISession) {
        if (session) return <a href='/personal'><Button style={{ }} variant="outlined">{session.user.firstname}</Button></a>;
        else return <><a href={'/login'}> <Button style={{ }} variant="contained">
            Login</Button></a></>;
    }

    
    React.useEffect(() => {
        setSession(getSession());
    }, []);


    return (<Grid container md={12} spacing={0}>
        <Grid md={4} style={{ display:'flex', justifyContent:'left' }}>
            <Grid container>
                <Grid md={6} xs={6} sm={6}>
                    <OurLogo />
                </Grid>
                <Grid md={6} xs={6} sm={6} style={{ justifyContent: 'center' }}>
                </Grid>
            </Grid>
        </Grid>
        <Grid md={4}>
            <Item2 style={{ fontSize: '2em' }}>{props.title}</Item2>
        </Grid>
        <Grid md={4} xs={12} sm={12} style={{ paddingLeft: '2em' }}>
            <Item2 style={{ fontSize: '1em', textAlign: 'right' }}>
                  {props.showLogin && renderLogin(session)}
                {props.helpAddress && <a className='invisible-mobile' href={'/' + props.helpAddress} target="_blank">
                    <Tooltip arrow title='FAQ'>
                        <Button variant="outlined" style={{ width: '100px', marginLeft: '.5em'  }}>Help</Button>
                    </Tooltip>
                </a>}
     
            </Item2>
        </Grid>
    </Grid>
    );
};
export default HeaderComponent;
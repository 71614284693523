import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button';

import { Item, Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import MoleculeView from '../../components/MoleculeView';
import { getPreview, getSpectra } from '../../services/Spectrum';
import { copyPublication } from '../../services/Publication';
import ISpectraModel from '../../schemas/Spectrum/ISpectraModel';
import ProgressControl from '../../components/ProgressControl';
import PublicationComponent from '../../components/Publication/PublicationComponent'
import ISpectrumPreviewModel from '../../schemas/Spectrum/ISpectrumPreviewModel';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';


type ISpectrumCompareProps = {
  spectrumPreview: ISpectrumPreviewModel
};

export const SpectrumComparePage: React.FC<ISpectrumCompareProps> = (props) => {

  const [spectrumPreview, setSpectrumPreview] = useState<ISpectrumPreviewModel>();
  const [spectrumLiterature, setSpectrumLiterature] = useState<ISpectraModel>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const pngStringPreview = encodeURIComponent(spectrumPreview?.graph ? spectrumPreview?.graph : '');
  const dataUriPreview = `data:image/png;base64,${pngStringPreview}`;

  const pngStringLiterature = encodeURIComponent(spectrumLiterature?.graph ? spectrumLiterature?.graph : '');
  const dataUriLiterature = `data:image/png;base64,${pngStringLiterature}`;

  let history = useHistory();

  const [publicationText, setPublicationText] = useState<string>();
  const [publicationUrn, setPublicationUrn] = useState<string>();


  const onChangePublicationView = (publicationText_ : string, urn: string) => {
    setPublicationText(publicationText_);
    setPublicationUrn(urn);
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
      const spectrId = (props as any).match.params['spectrid'];
      setSpectrumLiterature(await getSpectra(spectrId));

      const json = localStorage.getItem(spectrId);
      if (json) {
        const spectrumPreview = JSON.parse(json);
        setSpectrumPreview(await getPreview(spectrumPreview));
        localStorage.removeItem(spectrId);
      }
      else {
        setIsLoading(false);
        throw ('no data in local storage for spectra preview');
      }
       setIsLoading(false);
      }
      catch (e: any) {
        if (e instanceof NotAuthorizedError) {
          history.push({
            pathname: '/login', state: {
              backTo: history.location.pathname,
              welcomeMessage: true
            }
          })
        }
      }
    }
    fetchData().catch(console.error);
  }, []);


  return (
    <Grid container spacing={0} className='main-frame'>
      <ProgressControl isLoading={isLoading} />
      <Grid xs={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <Header title='Spectrum Search' showLogin={true} />
      <Grid container md={12} style={{ marginTop: '4em' }}>
        <Item style={{ textAlign: 'left', paddingLeft: '1em', paddingRight: '1em' }}>
          <Grid className='a' container md={12} spacing={0} style={{ display: 'flex' }}>
            <Grid container className='b' style={{ border: '10px', paddingRight: '.7em' }} spacing={2} md={5} >
              <Grid md={12}>
                <PublicationComponent publicationId={spectrumLiterature?.publication_id}
                  onChange={onChangePublicationView}
                />
              </Grid>
              <Grid md={12}>
                <Button
                  className='operation-button'
                  onClick={async (event) => await copyPublication(event,
                    spectrumLiterature?.molecule_svg,
                    spectrumLiterature?.spectrum_string,
                    publicationText,
                    publicationUrn
                  )}
                  variant='outlined'
                  sx={{ marginTop: '0.5em', float: 'right', color: '#505050', borderColor: '#505050' }}>
                  {'Copy'}
                </Button>
              </Grid>
              <div>
                <MoleculeView link={''}
                  moleculeId={spectrumLiterature?.compound_id}
                  svgContent={spectrumLiterature?.molecule_svg}
                  isMoleculeInContainer={true} />
              </div>
              <div style={{ marginTop: '.7em', marginBottom: '1em' }}>{spectrumLiterature?.spectrum_string}</div>

            </Grid>

            <Grid md={7} >
              <span style={{ fontSize: '1em', marginLeft: '.5em' }}>Requested spectrum</span>
              <Item style={{ fontSize: '1em', textAlign: 'left', marginTop: '0', paddingLeft: '1em', paddingRight: '1em', marginLeft: '.5em' }}>
                <div className='graph-container' style={{ paddingBottom: '2px' }}>
                  <img className='graph-qc' src={dataUriPreview} style={{ maxWidth: '100%' }} />
                </div>
              </Item>
              <span style={{ fontSize: '1em', marginLeft: '.5em' }}>Found spectrum</span>
              <Item style={{ fontSize: '1em', textAlign: 'left', paddingLeft: '1em', marginTop: '0', paddingRight: '1em', marginLeft: '.5em' }}>
                <div className='graph-container' style={{ paddingBottom: '2px' }}>
                  <img className='graph-qc' src={dataUriLiterature} style={{ maxWidth: '100%' }} />
                </div>
              </Item>

            </Grid>
          </Grid>
        </Item>

      </Grid>
    </Grid >
  );
}
export default SpectrumComparePage;

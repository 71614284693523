import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import { Item2 } from '../../styles';
import { keepSession } from '../../services/Login';
import ISession from '../../schemas/Login/ISession';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import { fetchPost } from '../../services/GettingData';

type ILoginProps = {
  location: ILoginPS
};

type ILoginPS = {
  state: {
    backTo: string,
    welcomeMessage?: boolean,
    email?: string,
  }
}

export const LoginPage: React.FC<ILoginProps> = (props) => {

    const searchParams = new URLSearchParams(useLocation().search);
    const fromParam = searchParams.get("from");
    
    const [email, setEmail] = useState<string>(props.location.state?.email ? props.location.state?.email : '');
    const [password, setPassword] = useState<string>('');
    const [isSubmissionOpen, setSubmissionOpen] = useState<boolean>(false);
    const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [welcomeMessage, displayWelcomeMessage] = React.useState<boolean>(false);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const history = useHistory();

    const keepTheToken = (loginSession: ISession) => {
        keepSession(loginSession);
    }

    React.useEffect(() => {
        const email = (props as any).match.params['email'];
        if (email)
            setEmail(email);
        displayWelcomeMessage(!!props.location?.state?.welcomeMessage);
    }, []);


    React.useEffect(() => {
        setSubmissionOpen(!!email && !!password && email.indexOf('@') > -1);
    }, [email, password]);


    
    const showError = (errorMessage: string) => {
        setErrorDialogOpened(true);
        setErrorMessage(errorMessage);
    }

    
    const onCloseErrorDialog = () => {
        setErrorDialogOpened(false);
    }
      

    const accountLogin = () => {
        const fetchData = async () => {
            const url = '/user/account/login';
            const response = await fetchPost(url,
                JSON.stringify({
                    'password': password,
                    'email': email,
                }), false);
            if (response.ok) {
                keepTheToken(await response.json());
                //setLoading(false);
                goBack();
            }
            else {
                if (response.status === 404)
                   showError('User not found');
                if (response.status === 403)
                    showError('Incorrect password or email');
                if (response.status === 422)
                    showError('Error in processing');
            }
        }
        fetchData()
            .catch(console.error);
    }


    const goBack = () => {
        
        if (fromParam)
            history.push(fromParam);
        else
            if (props.location?.state?.backTo)
               history.push(props.location.state.backTo);
            else
                history.push('/');
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }


    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();     
    }


    return (
        <Grid container className='main-frame' spacing={0}>
            <Grid container md={12}>
                <Item2><div style={{ height: '2em' }}></div></Item2>
            </Grid>
            <ErrorDialog isDialogOpened={isErrorDialogOpened}
                errorMessage={errorMessage}
                warningMessage={''}
                onClose={onCloseErrorDialog} />

            <Header title='Login' />
            <Grid style={{ height: '2em' }} xs={12}></Grid>

        {welcomeMessage &&
          <Grid xs={12} container style={{marginTop:'2em'}}>
            <Grid md={3} xs={12}></Grid>
            <Grid md={6} xs={12} style={{fontSize:'medium'}}>
              <div>
                <div style={{marginBottom:'1em'}}>Dear Client,</div>
                <div>Thank you for your interest in Odanchem services.</div>
                You need to be a registered user to have access to
                <ul>
                    <li>Search in publications</li>
                    <li>Search by spectrum</li>
                </ul>    
                <span style={{fontWeight:'600'}}>Please log in:</span></div></Grid>
            <Grid md={3} xs={12}></Grid>
          </Grid>}
            <Grid xs={12} container style={{marginTop:'2em'}}>
                <Grid md={3} xs={12}></Grid>
                <Grid md={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField className='reg-line'
                        type="email"
                        value={email}
                        focused={true}
                        autoFocus
                        sx={{
                            input: {
                                "&::placeholder": {
                                    opacity: .5,
                                },
                            },
                            label: { color: 'blue' }
                        }}
                        onChange={(c) => {
                            setEmail(c.target.value)
                        }}
                        style={{ width: '100%', marginTop: '1em' }}
                        placeholder='email' />
                </Grid>
                <Grid md={3} xs={12}></Grid>
            </Grid>

            <Grid xs={12} container>
                <Grid md={3} xs={12}></Grid>
                <Grid md={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField className='reg-line'
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                  position="start"
                                  classes={{ positionStart: "0px" }}
                                >
                                  <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                          }}                        
                        value={password}
                        sx={{
                            input: {
                                "&::placeholder": {
                                    opacity: .5,
                                },
                            },
                            label: { color: 'blue' }
                        }}
                        onChange={(c) => {
                            setPassword(c.target.value)
                        }}
                        helperText=""
                        style={{ width: '100%', marginTop: '1em' }}
                        placeholder='password' />
                </Grid>
                <Grid md={3} xs={12}></Grid>
            </Grid>

            <Grid container xs={12} style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
                <Grid xs={12} style={{ padding: '1em', display: 'flex', justifyContent: 'center' }}>
                  <div><span style={{marginRight:'1em'}}>Do not have account?</span>
                  <a href='/register'>Register here</a>
                  </div>
                </Grid>
                <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <span style={{marginRight:'1em'}}>Forget the password?</span>
                    <Link to={{
                        pathname: '/restore',
                        state: {
                            email: email
                        }
                    }}>Restore here
                    </Link>
                  </div>
                </Grid>
            </Grid>

            <Grid container xs={12} style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
                <Grid>
                    <Button disabled={!isSubmissionOpen} onClick={() => accountLogin()} variant="contained">Submit</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LoginPage;

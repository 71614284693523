import { fetchPost } from '../services/GettingData';
import IResolvedMolecule from '../schemas/IResolvedMolecule';
import config from '../config.json';

async function nameToMolecule(name: string): Promise<IResolvedMolecule> {
    const response = await fetchPost('/misc/name-to-molfile?name=' + name, '', true, config.business);
    const molecule = await response.json();
    return molecule;
}

async function molToSvg(mol: string): Promise<string> {
    const response = await fetchPost('/misc/draw', JSON.stringify(mol), true, true);
    const molecule = await response.text();
    return molecule;
}

export { nameToMolecule, molToSvg };